
import { isObject as _isObject } from "lodash";

export default {
	name: "CoreBlockGrid",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		bodyClasses() {
			const classList = ["block__body"];
			if (!this.hasChildren) {
				classList.push("block__body--empty");
			}
			return classList;
		},
		blockChildren() {
			return this.settings?.blocks || [];
		},
		blockChildrenCount() {
			return this.blockChildren.length;
		},
		blockId() {
			return this.settings.id;
		},
		blockVariantLayout() {
			return this.settings?.variants?.layout || "rows";
		},
		blockBackgroundImage() {
			const imgSrc = _isObject(this.settings.image)
				? this.settings.image.src
				: this.settings.image;
			const bgPosition = _isObject(this.settings.image)
				? this.settings.image?.focal?.bgPosition
				: "center";
			const bgStyle = {
				"background-image": `url('${imgSrc}')`,
				"background-position": bgPosition,
				"padding-top": "12rem",
			};
			return this.settings.image ? bgStyle : "";
		},
		sectionMainClasses() {
			const classList = ["block__section_main"];
			let columnCount = this.settings["column-count"];
			if (columnCount < 2) {
				columnCount = 2;
			}
			if (columnCount > 6) {
				columnCount = 6;
			}
			classList.push(`block__section_main--${columnCount}_column`);
			return classList;
		},
		nestedLevelNext() {
			return this.nestedLevel + 1;
		},
		/*
			todo the childBlockComponent property is only useful for the builder
			so it should be separated from this block's logic
		*/
		childBlockComponent() {
			if (this.isEditable) {
				return "ForgeControlBlock";
			}
			return "CoreBlock";
		},
	},
};
